<template>
  <div class="FootBox">
    <!-- logo -->
    <div id="foot_left">
      <img src="../assets/logo.png" alt="" />
      <div>Innovation Mobile Development Studio</div>
      <div>创臻移动开发工作室</div>
    </div>

    <div id="foot_content">
      <FootTiem :link="true" head="新公网安备" content="65010902000862号" src="https://gywlg.cn/file/1720611090853.png" a="https://beian.mps.gov.cn/#/query/webSearch?code=65010902000862"/>
      <FootTiem :link="true" head="ICP备案" content="豫ICP备2024074576号-1" src="http://gw.gywlg.cn/icp.ico" a="http://beian.miit.gov.cn/" target="_blank"/>
      <FootTiem :link="false" head="地址：" content="新疆农职大 图书馆5楼" a="#"/>
    </div>

    <!-- <div id="foot_right">

    </div> -->
  </div>
</template>

<script>
import FootTiem from "@/components/FootTiem.vue";
export default {
  components: {
    FootTiem,
  },
};
</script>

<style lang="less" scoped>
/* 整体 */
.FootBox {
  width: 100%;
  min-height: 280px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.24);

  #foot_left {
    width: 25%;
    height: 100%;
    text-align: center;
    // background-color: rgb(72, 92, 92);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 100px;
      height: 100px;
    }
    div {
      font-weight: 900;
      font-size: 20px;
      color: rgb(133, 137, 141);
    }
  }

  #foot_right{
    flex: 1;
    flex-shrink: 1;
    background-color: aqua;
    height: 100%;
  }

  #foot_content {
    // width: 30%;
    flex: 1;
    justify-content: center;
    height: 100%;
    // background-color: aquamarine;
    display: flex;
    // flex-direction: column;
    box-sizing: border-box;
    padding: 20px !important;
    flex-wrap: wrap;
    background-color: white;
  }
}
</style>