<template>
  <div class="index_content_box_Item2_item_titleBox">
    <div class="index_content_box_Item2_item_title_Bg">{{ title_bg }}</div>
    <div class="index_content_box_Item2_item_title_on">{{ title_txt }}</div>
  </div>
</template>

<script>
export default {
  props: ["title_bg", "title_txt"],
};
</script>

<style lang="less">
// 标题
.index_content_box_Item2_item_titleBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  // 标题背景文字
  .index_content_box_Item2_item_title_Bg {
    font-weight: 900;
    font-size: 30px;
    color: #bcc1c25d;
  }
  // 标题前景文字
  .index_content_box_Item2_item_title_on {
    font-weight: 900;
    font-size: 22px;
    color: rgb(13, 78, 219);
    position: relative;
    top: -25px;
  }
}
</style>