import Vue from 'vue';
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import index from '@/view/index.vue'
const router = new VueRouter({
    routes: [
        {
            path: '/',
            component: index
        },
        {
            path: '/content4',
            redirect:"/"
        },
        {
            path: '/content1',
            redirect:"/"
        },
        {
            path: '/content2',
            redirect:"/"
        },
        {
            path: '/content3',
            redirect:"/"
        }
    ]
});

export default router;