<template>
  <div class="foot_itemBox">
    <div v-if="!link" class="foot_itemBox1">
      <div class="foot_head">{{head}}</div>
      <div class="foot_content">{{content}}</div>
    </div>
    <a v-else class="foot_itemBox1" :href="a">
      <div class="foot_head">
        <img :src="src" alt="" />
        <div>{{head}}</div>
      </div>
      <div class="foot_content">{{content}}</div>
    </a>
  </div>
</template>

<script>
export default {
  props: ["link","a","head","content","src"],
};
</script>

<style>
.foot_itemBox,
.foot_itemBox1 {
  display: flex;
  align-items: center;
}

.foot_itemBox a{
  text-decoration: none;
}

.foot_itemBox{
  margin-left: 10px !important;
}

.foot_head img {
  width: 15px;
  height: 15px;
}

.foot_head {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(20, 155, 233);
  /* box-sizing: border-box !important; */
  padding: 5px !important;
  font-size: 13px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-sizing: border-box;
  color: white;
}

.foot_content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aqua;
  /* box-sizing: border-box !important; */
  padding: 5px !important;
  font-size: 13px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: white;
  font-weight: 900;
}
</style>