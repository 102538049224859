<template>
  <div id="sidbar_box">
    <!-- 菜单 -->
    <el-menu
      class="el-menu-demo"
      mode="horizontal"
      background-color="#ffffff00"
      text-color="#ffff"
      active-text-color="#409EFF"
      
      router
    >
      <!-- 菜单选项 -->
      <!-- <el-submenu index="2" class="sidbar_item">
        <template slot="title">我的工作台</template>
        <el-menu-item index="2-1">选项1</el-menu-item>
        <el-menu-item index="2-2">选项2</el-menu-item>
        <el-menu-item index="2-3">选项3</el-menu-item>
      </el-submenu> -->

      <el-menu-item class="sidbar_item"
        ><a href="#content3">相关业绩</a></el-menu-item
      >
      <el-menu-item class="sidbar_item"
        ><a href="#content2">主要成员</a></el-menu-item
      >
      <el-menu-item class="sidbar_item"
        ><a href="#content1">工作室简介</a></el-menu-item
      >
      <el-menu-item class="sidbar_item"><a href="#content4">首页</a></el-menu-item>

      <!-- logo -->
      <div id="logo">
        <img src="../assets/logo.png" />
        <div id="sidbar_title">创臻移动开发工作室</div>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
// 菜单栏整体
#sidbar_box {
  z-index: 100;
  width: 100%;
  height: 50px;
  // background-color: aqua;
  position: absolute;
  top: 0px;
  background-color: #0e69c5;
  display: flex;
  // color: #17d48c00;
}

a{
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

// 菜单样式
.el-menu-demo {
  margin-left: auto !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 20px !important;
  font-weight: 900;
  .sidbar_item {
    margin-left: 10px !important;
  }
}

// 父级菜单悬浮背景色
.el-submenu__title:focus,
.el-submenu__title:hover {
  outline: 0 !important;
  color: #409eff !important;
  background: none !important;
}

// 子级菜悬浮背景色
.el-menu-item:hover {
  outline: 0 !important;
  color: #409eff !important;
  background: #40a0ff00 !important;
}

// 激活时背景色
.el-menu-item.is-active {
  color: #409eff !important;
  background: #40a0ff00 !important;
}

// logo
#logo {
  font-weight: 900;
  color: white;
  display: flex;
  font-size: 20px;
  align-items: center;
  margin-right: auto !important;
  margin-left: 20px !important;
  // margin-left: 20px !important;
  img {
    width: 50px;
    height: 50px;
  }
}
</style>