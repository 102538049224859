<template>
  <div id="app">
   <router-view></router-view>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
*{
  margin: 0 !important;
  padding: 0 !important;
}
#app{
    overflow-x:hidden !important;
}
</style>
