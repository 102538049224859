<template>
  <div id="index_box">
    <!-- 菜单 -->
    <sidbar />
    <!-- 首页封面 -->
    <div id="index_Content_item1">
      <div id="index_Content_item1_content">
        <transition name="el-zoom-in-bottom">
          <div class="index_Content_item1_content_title" v-show="title">
            创臻移动开发工作室
          </div>
        </transition>

        <transition name="el-zoom-in-bottom">
          <div class="index_Content_item1_content_title_eg" v-show="title_eg">
            Innovation Mobile Development Studio
          </div>
        </transition>

        <transition name="el-zoom-in-bottom">
          <div class="index_Content_item1_content_title_info" v-show="info1">
            无 / 限 / 创 / 造 / 未 / 来
          </div>
        </transition>

        <transition name="el-zoom-in-bottom">
          <div
            class="index_Content_item1_content_title_info"
            style="
              background-image: -webkit-linear-gradient(left, #009fff, #ec2f4b);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            "
            v-show="info2"
          >
            高效严谨
          </div>
        </transition>

        <transition name="el-zoom-in-bottom">
          <div
            class="index_Content_item1_content_title_info"
            style="
              background-image: -webkit-linear-gradient(left, #F27121, #ec2f4b);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            "
            v-show="info3"
          >
            务实创新
          </div>
        </transition>

        <transition name="el-zoom-in-bottom">
          <div
            class="index_Content_item1_content_title_info"
            style="
              transition: background-image 0.5s linear;
              background-image: -webkit-linear-gradient(left, #ec2f4b, #ec2f4b);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            "
            v-show="info4"
          >
            合作共赢
          </div>
        </transition>
      </div>
    </div>

    <!-- 首页内容 -->
    <div id="index_content_box" @scroll="contentScroll">
      <!-- 空白区域 -->
      <div id="index_content_box_Item1">
        <a name="content4"></a>
      </div>
      <!-- 内容区域 -->
      <div id="index_content_box_Item2">
        <!-- 工作室简介 -->
        <div class="index_content_box_Item2_item marginDiv" id="content1">
          <a name="content1"></a>
          <ContentTitle title_bg="BRIEF INTRODUCTION" title_txt="工作室简介" />
          <div class="index_content_box_Item2_item_content">
            创臻移动开发工作室契合国家近几年大力支持大师技能工作室建设的目的，贴近时代发展的需要，能够从本地化学生的学情分析出发，适应国家大力发展职业教育，以促进高技能人才培养的目标。工作室于2014年12月进行授牌，位于图书馆五楼，主要从事开源技术推广、学生学徒培养、对外服务等方面的工作。通过六年的建设与运行，已经具备了良好的建设基础，为分院人才培养和教育教学改革发展起到了一定的推动作用。
          </div>
        </div>

        <!-- 工作室团队 -->
        <a name="content2"></a>
        <div class="index_content_box_Item2_item marginDiv">
          <ContentTitle title_bg="TEAM MEMBERS" title_txt="团队主要成员" />
          <div class="index_content_box_Item2_item_content">
            领衔人：李桂珍
            （校内）、何伟（校外、新疆天衡创新研究院有限公司项目经理）
          </div>

          <div class="index_content_box_Item2_item_content cardBotton">
            工作室成员由校内教师、企业专家共同组成。截至目前工作室已经组建了一直由校企双领衔人牵头的结构配比合理的师资队伍。共7名团队核心成员，由3家单位组成，高级职称占比达57%，老中青年龄搭配。同时根据工作室活动组织需要，每年在分院学生中选取10名学生团队核心成员，并实现了以老带新，学生团队的每年更新换代。目前工作室构建了一支由稳定的教师团队和每年换届以老带新的学生团队队伍。
          </div>

          <div class="index_content_box_Item2_item_content">
            <el-carousel
              :interval="4000"
              type="card"
              height="300px"
              class="index_content_box_Item2_item_content_card"
              align="center"
            >
              <el-carousel-item
                v-for="(item, index) in MEMBERS_info"
                :key="index"
                class="index_content_box_Item2_item_content_card_content"
              >
                <!-- 标题 -->
                <div class="card_content_title" style="color: white">
                  <div class="card_content_title_name">{{ item.name }}</div>
                  <div class="card_content_title_egname">{{ item.enName }}</div>
                  <div class="card_content_title_flats">{{ item.flats }}</div>
                </div>
                <hr />
                <!-- 信息 -->
                <div class="card_content_info">
                  <!-- 信息项 -->
                  <div class="card_content_info_item">
                    <div class="card_content_info_item_title">
                      <div>专业技术职务：</div>
                      <div class="info">{{ item.office }}</div>
                    </div>

                    <div class="card_content_info_item_title">
                      <div>年龄：</div>
                      <div class="info">{{ item.age }}岁</div>
                    </div>

                    <div class="card_content_info_item_title">
                      <div class="">学位：</div>
                      <div class="info">
                        <!-- <el-tag type="success">{{item.degree}}</el-tag> -->
                        {{ item.degree }}
                      </div>
                    </div>

                    <div class="card_content_info_item_title">
                      <div class="">所在单位：</div>
                      <div class="info">{{ item.flats }}</div>
                    </div>

                    <div class="card_content_info_item_title">
                      <div class="">组织分工：</div>
                      <div class="info">
                        {{ item.division }}
                      </div>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <!-- 工作室相关业务成绩 -->
        <a name="content3"></a>
        <div
          class="index_content_box_Item2_item marginDiv"
          style="height: auto !important"
        >
          <ContentTitle
            title_bg="BUSINESS PERFORMANCE"
            title_txt="工作室相关业务成绩"
          />
          <div class="index_content_box_Item2_item_content">
            1、工作室服务于分院软件技术专业，为该专业学生学徒制培养提供保障。
            软件技术专业于2004年开始招生，目前共有342名学生。随着2014年软件技术专业学徒制试点开始，本工作室为学徒制学生培养提供了保障。2014年起工作室依托参与企业昌吉市鑫开源信息技术有限公司面向5名学生提供了学徒制岗位，带领学生参与了微信小程序项目的研发工作，并推荐其中2名学生在相关岗位就业。
          </div>

          <el-card :body-style="{ padding: '0px' }" class="imageCard">
            <img
              src="../assets/工作室成员参与微信小程序开发.jpg"
              class="image"
            />
            <div style="padding: 14px">
              <!-- <span>工作室成员参与微信小程序开发</span> -->
            </div>
          </el-card>
          <div class="index_content_box_Item2_item_content">
            2、工作室为分院技能大赛提供保障，以赛促教，注重学生培养。
            工作室注重学生专业技能训练和培养。依托工作室，通过定期组织专业技能研讨活动，加强学生Android、Blockly等相关技术学习。自2014年成立至今，工作室为分院参与的自治区以及全国职业院校技能大赛移动互联网应用软件开发赛项、软件测试赛项、云计算技术应用赛项、人工智能技术应用赛项提供了大赛训练场所，同时工作室成员参与了4项大赛的组织和技术指导任务，辅导工作室学生多次取得自治区一等奖和全国三等奖的好成绩。除此以外，工作室重视学生双创教育，鼓励和指导多名学生参加互联网+大学生创新创业大赛、中美青年创客大赛，截至目前工作室共培养3支学生团队12名学生分别获得中美青年创客大赛沈阳赛区决赛二等奖、自治区铜奖的好成绩。
          </div>

          <div class="jiangBox">
            <div
              class="jiangBox_item"
              v-for="(item, index) in jiang"
              :key="index"
            >
              <el-image
                lazy
                fit="cover"
                style="width: 100%; height: 100%"
                :src="item.url"
                :preview-src-list="[item.url]"
              >
              </el-image>
            </div>
          </div>

          <div class="index_content_box_Item2_item_content">
            3、工作室丰富了学生社团活动
            工作室为分院两个学生社团：Android俱乐部和人工智能社团以及谷歌开发者社区（昌吉GDG）提供固定活动场所。主要围绕谷歌开源技术TensorFlow、Android、Blockly等通过定期开展主题讲座、学生动手实践、交流研讨等方式开展了一系列活动，为学生专业兴趣培养以及依托新技术进行创新创业起到一定推动作用。自2014年至今工作室面向学生社团每年举办活动不少于10场。
          </div>

          <div class="block">
            <el-carousel trigger="click" height="500px" class="EDGBox">
              <el-carousel-item v-for="(item, index) in EDG" :key="index">
                <el-image
                  lazy
                  class="EDGBox_iamge"
                  style="width: 100%; height: 100%"
                  :src="item.url"
                  :preview-src-list="EDG2"
                >
                </el-image>
              </el-carousel-item>
            </el-carousel>
          </div>

          <div class="index_content_box_Item2_item_content">
            4、工作室为分院对外服务提供技术支撑。
            工作室每年为分院承担的谷歌师资培训班和校园开放日活动提供技术支撑。自2014年工作室建立至今为分院承办的5届谷歌中学师资培训班提供技术支持和后勤服务工作，同时为两届校园开放日活动展示了工作室高科技产品。2019年工作室承办了面向全疆高职学生的创新训练营活动，共有来自全疆8所高职院校的24名学生参与了活动，学生通过项目开发、汇报展示、相互交流，取得了较好的效果。2012年工作室研发的基于Android平台的新生入学助手app应用于新生接待工作，并申报了软件著作权一项。
          </div>

          <div class="zhuzuoquanBox">
            <div
              class="zhuzuoquan_item"
              v-for="(item, index) in zhuzuoquan"
              :key="index"
            >
              <el-image
                lazy
                class="EDGBox_iamge"
                style="width: 100%; height: 100%"
                :src="item.url"
                :preview-src-list="[item.url]"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 底部 -->
      <foot />
    </div>
  </div>
</template>

<script>
import sidbar from "@/components/sidbar.vue";
import ContentTitle from "@/components/ContentTitle.vue";
import foot from "@/components/Foot.vue";
export default {
  components: {
    sidbar,
    ContentTitle,
    foot,
  },
  computed: {},
  created() {
    // console.log(this.zhuzuoquan);
  },
  beforeCreate() {
    this.url = "gw.gywlg.cn";
    // this.url=location.href.split("http://")[1].split("/")[0]
  },
  data() {
    return {
      url: "gw.gywlg.cn",
      // 著作权
      zhuzuoquan: [
        // this.url,
        { url: `http://${this.url}/zhuzuoquan/1.jpg` },
        { url: `http://${this.url}/zhuzuoquan/吾麦热软著2020.jpg` },
        { url: `http://${this.url}/zhuzuoquan/软件著作权2017.jpg` },
      ],
      // 奖项
      jiang: [
        {
          url: `http://${this.url}/jiang/2017年移动互联网应用软件开发国赛三等奖.jpg`,
        },
        {
          url: `http://${this.url}/jiang/2018年移动互联网应用软件开发国赛三等奖.jpg`,
        },
        { url: `http://${this.url}/jiang/2018移动应用开发国赛三等奖.jpg` },
        { url: `http://${this.url}/jiang/2019移动互联省赛二等奖.jpg` },
        {
          url: `http://${this.url}/jiang/2020中华人民共和国第一届技能大赛裁判证书.jpg`,
        },
        { url: `http://${this.url}/jiang/2020学生参与世界技能大赛广州.jpg` },
        { url: `http://${this.url}/jiang/2020移动应用开发全国三等奖.jpg` },
        {
          url: `http://${this.url}/jiang/2020移动应用软件开发国赛三等奖.jpg`,
        },
        {
          url: `http://${this.url}/jiang/2021技能大赛优秀指导教师-李桂珍.jpg`,
        },
        { url: `http://${this.url}/jiang/2021省赛一等奖-张成龙.jpg` },
        {
          url: `http://${this.url}/jiang/2021移动应用开发个人赛国赛三等奖-吾麦热.jpg`,
        },
        { url: `http://${this.url}/jiang/2021移动应用开发国赛三等奖.JPG` },
        {
          url: `http://${this.url}/jiang/2021移动应用开发省赛一等奖-吾麦热.jpg`,
        },
        {
          url: `http://${this.url}/jiang/2021自治区团委挑战杯大赛获奖证书.jpg`,
        },
        { url: `http://${this.url}/jiang/IMG_20130620_153617.jpg` },
        { url: `http://${this.url}/jiang/IMG_20201107_123246.jpg` },
        { url: `http://${this.url}/jiang/mmexport1608008629909.jpg` },
        { url: `http://${this.url}/jiang/学生参与谷歌夏令营.jpg` },
        {
          url: `http://${this.url}/jiang/技能大赛-移动互联国赛三等奖-2017年.jpeg`,
        },
        {
          url: `http://${this.url}/jiang/技能大赛-移动互联网省赛一等奖-2017年.jpg`,
        },
      ],
      // EDG活动信息
      EDG: [
        { url: `http://${this.url}/EDG/1.JPG` },
        { url: `http://${this.url}/EDG/2.jpeg` },
        { url: `http://${this.url}/EDG/3.jpg` },
        { url: `http://${this.url}/EDG/4.jpg` },
        { url: `http://${this.url}/EDG/5.jpg` },
        { url: `http://${this.url}/EDG/6.JPG` },
        { url: `http://${this.url}/EDG/7.jpg` },
        { url: `http://${this.url}/EDG/8.jpg` },
        { url: `http://${this.url}/EDG/9.jpg` },
        { url: `http://${this.url}/EDG/10.jpg` },
        { url: `http://${this.url}/EDG/11.png` },
        { url: `http://${this.url}/EDG/12.png` },
        { url: `http://${this.url}/EDG/13.jpg` },
      ],
      EDG2: [
        `http://${this.url}/EDG/1.JPG`,
        `http://${this.url}/EDG/2.jpeg`,
        `http://${this.url}/EDG/3.jpg`, //ok
        `http://${this.url}/EDG/4.jpg`,
        `http://${this.url}/EDG/5.jpg`, //ok
        `http://${this.url}/EDG/6.JPG`, //ok
        `http://${this.url}/EDG/7.jpg`, //ok
        `http://${this.url}/EDG/8.jpg`,
        `http://${this.url}/EDG/9.jpg`,
        `http://${this.url}/EDG/10.jpg`,
        `http://${this.url}/EDG/11.png`,
        `http://${this.url}/EDG/12.png`, //ok
        `http://${this.url}/EDG/13.jpg`,
      ],
      // 团队成员信息
      MEMBERS_info: [
        {
          name: "李桂珍",
          enName: "LI GUI ZHEN",
          age: "39",
          office: "副教授",
          degree: "工程硕士",
          flats: "新疆农职院",
          division: "工作室总体规划设计",
        },
        {
          name: "何伟",
          enName: "HE WEI",
          age: "27",
          office: "软件设计师（中级）",
          degree: "工学学士",
          flats: "新疆银海鼎峰软件有限公司",
          division: "承接项目开发、学生学徒培养",
        },
        {
          name: "杨功元",
          enName: "YANG GONG YUAN",
          age: "49",
          office: "教授",
          degree: "工程硕士",
          flats: "新疆农职院",
          division: "参与工作室建设、规划",
        },
        {
          name: "徐玉莲",
          enName: "XV YV LIAN",
          age: "42",
          office: "副教授",
          degree: "工程硕士",
          flats: "新疆农职院",
          division: "活动组织、技能大赛辅导、对外服务",
        },
        {
          name: "李欣",
          enName: "LI XIN",
          age: "42",
          office: "副教授",
          degree: "工程硕士",
          flats: "新疆农职院",
          division: "课程建设、技能大赛辅导、队伍服务",
        },
        {
          name: "姚居文",
          enName: "YAO JV WEN",
          age: "27",
          office: "软件设计师（中级）",
          degree: "工学学士",
          flats: "新疆农职院",
          division: "活动实施、技能大赛辅导、承接项目开发",
        },
        {
          name: "王海杰",
          enName: "WANG HAI JIE",
          age: "35",
          office: "",
          degree: "高级软件工",
          flats: "昌吉市鑫开源信息技术有限公司",
          division: "工作室总体规划设计、学生学徒培养、孵化",
        },
      ],
      title: false,
      title_eg: false,
      info1: false,
      info2: false,
      info3: false,
      info4: false,
    };
  },
  methods: {
    getDivision(str) {
      return str.split("、");
    },
    contentScroll(e) {
      const el = document.querySelector("#index_content_box_Item2");
      // console.log(e.target.scrollTop);
      if (e.target.scrollTop > 80) {
        el.style.width = "70%";
      }
      if (e.target.scrollTop > 150) {
        el.style.width = "80%";
      }
      if (e.target.scrollTop > 200) {
        el.style.width = "90%";
      }
      if (e.target.scrollTop > 250) {
        el.style.width = "100%";
        // el.style.border_top_left_radius = "0px !important";
        // el.style.border_top_right_radius = "0px !important";
      }
    },
  },
  mounted() {
    // 文字加载动画
    setTimeout(() => {
      this.title = true;
    }, 500);
    setTimeout(() => {
      this.title_eg = true;
    }, 1000);
    setTimeout(() => {
      this.info1 = true;
    }, 1500);
    setTimeout(() => {
      this.info2 = true;
    }, 2000);
    setTimeout(() => {
      this.info3 = true;
    }, 2500);
    setTimeout(() => {
      this.info4 = true;
    }, 3000);
  },
};
</script>

<style lang="less">
#index_box {
  position: fixed !important;
  width: 100vw !important;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
  overflow: hidden;
}
#index_Content_item1 {
  // margin-top: 50px !important;
  width: 100%;
  height: 100vh !important;
  display: flex;
  align-items: center;
  background-image: url(../assets/EDG/12.png);
  background-repeat: no-repeat;
  background-size: cover;
}
#index_Content_item1_content {
  // width: 500px;
  height: 400px;
  // background-color: bisque;
  margin-left: 400px !important;
  display: flex;
  flex-direction: column;
  padding: 10px !important;
}

// 工作室中文名
.index_Content_item1_content_title {
  display: flex;
  // justify-content: center;
  font-weight: 900;
  font-size: 40px;
  color: white;
}

// 工作室英文名
.index_Content_item1_content_title_eg {
  transition: background-image 0.5s linear;
  font-weight: 900;
  font-size: 20px;
  background-image: -webkit-linear-gradient(left, #009fff, #ec2f4b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // animation: test 5s infinite;
  margin-top: 10px !important;
}

// 描述信息
.index_Content_item1_content_title_info {
  color: white;
  font-weight: 900;
  font-size: 17px;
  margin-top: 10px !important;
}

// 首页内容
#index_content_box {
  scroll-behavior: smooth;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  // background-color: rgb(63, 43, 43);
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
  justify-content: center;
  // pointer-events: none !important;

  // 空白区域
  #index_content_box_Item1 {
    pointer-events: none !important;
    flex-shrink: 1;
    width: 100%;
    height: 100vh !important;
    // background-color: #48b1bfb2;
  }

  // 内容区域
  #index_content_box_Item2 {
    width: 60%;
    // height: 100vh !important;
    background-color: white;
    // border-radius: 60px;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    transition: all 0.2s;
    display: flex;
    // justify-content: center;
    // text-indent: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

// 内容区域中的内容
.index_content_box_Item2_item {
  box-sizing: border-box;
  padding: 30px !important;
  width: 90%;
  // height: 300px;
  // background-color: aqua;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh !important;

  // 内容
  .index_content_box_Item2_item_content {
    font-weight: 600;
    margin-top: 7px !important;
  }
}

// 成员介绍car盒子
.index_content_box_Item2_item_content_card {
  width: 55vw;
  // background-color: aqua;
  // padding: 10px !important;
}
.index_content_box_Item2_item_content_card_content {
  // width: 100%;
  height: 100%;
  padding: 20px !important;
  background-color: rgb(19, 144, 253);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

//个别需要margin的元素
.cardBotton {
  margin-bottom: 30px !important;
}

// 成员信息姓名栏
.card_content_title {
  width: 100%;
  // height: 20px;
  // background-color: #48b1bf;
  display: flex;
  align-items: flex-end;
  // 姓名二
  .card_content_title_egname {
    font-size: 10px !important;
  }
  // 单位
  .card_content_title_flats {
    margin-left: auto !important;
    font-size: 14px;
    color: white;
  }
}

// 成员信息
.card_content_info {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px !important;
  background-color: rgb(19, 144, 253);
  .card_content_info_item {
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    color: white !important;
    .card_content_info_item_title {
      display: flex;
      padding-right: 2rem !important;
      margin-bottom: 10px !important;
    }
    .info {
      margin-left: auto !important;
      display: flex;
      .item_tag {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 5px !important;
      }
    }
  }
}

.marginDiv {
  padding-top: 200px !important;
}

// 图片卡片样式
.imageCard {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 100%;
  border-radius: 10px;

  img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    // margin-left: -15px !important;
  }
}

.EDGBox_iamge {
  // margin-left: -50px !important;
  // border-radius: 10px ;
}

.EDGBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px !important;
  border-radius: 10px;
  overflow: hidden;
}

.block {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  width: 100%;
  // height: 800px;
  // overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.jiangBox {
  width: 100%;
  // height: 600px;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  // background-color: #48b1bf;
  display: flex;
  border-radius: 10px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px !important;
  .jiangBox_item {
    width: 300px;
    height: 200px;
    // background-color: rebeccapurple;
    border-radius: 10px;
    margin: 30px !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    // margin-left: -50px !important;
  }
}

.zhuzuoquanBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px !important;
  box-sizing: border-box;
  // background-color: aliceblue;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  .zhuzuoquan_item {
    width: 200px;
    height: 300px;
    // background-color: rebeccapurple;
    margin: 10px !important;
  }
}

@media screen and (max-width: 550px) {
  #sidbar_title {
    display: none;
  }
}

// 自适应
@media screen and (max-width: 1050px) {
  #index_Content_item1 {
    justify-content: center !important;
  }
  .index_content_box_Item2_item {
    padding: 0px !important;
    padding-top: 200px !important;
    width: 90% !important;
  }
  .index_content_box_Item2_item_content_card {
    width: 99vw !important;
  }
  #index_Content_item1_content {
    margin-left: 0px !important;
    justify-content: space-around;
  }

  .el-carousel__item--card {
    width: 100% !important;
  }

  #foot_left {
    width: 100% !important;
  }
  #foot_content {
    width: 100% !important;
  }

  .card_content_info_item_title {
    font-size: 10px;
  }

  .index_content_box_Item2_item_content_card .el-carousel__item,
  .el-carousel__mask {
    // right: 10% !important;
    left: -25% !important;
  }

  .index_content_box_Item2_item_title_Bg {
    font-size: 25px !important;
  }

  .index_content_box_Item2_item_title_on {
    font-size: 20px !important;
  }

  .EDGBox {
    height: 350px !important;
    padding: 0 !important;
  }
  .el-carousel__container {
    // left: 25% !important;
  }
}
</style>